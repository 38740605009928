import React from "react"
import styled from '@emotion/styled'
import { css } from "@emotion/react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import fetchWithTimeout from '../utils/fetchWithTimeout'
import Layout from "../components/layout"



import MailchimpSubscribe from "react-mailchimp-subscribe"

const url = "https://singlethreaded.us17.list-manage.com/subscribe/post?u=908307277eb0595bbede9f9a3&amp;id=e3a8db83b6&amp;f_id=000642e0f0";

// simplest form (only email)
const SimpleForm = () => <MailchimpSubscribe url={url}/>

// use the render prop and your custom form
const CustomForm = () => (
  <MailchimpSubscribe
    url={url}
    render={({ subscribe, status, message }) => (
      <div>
        <SimpleForm onSubmitted={formData => subscribe(formData)} />
        {status === "sending" && <div style={{ color: "blue" }}>sending...</div>}
        {status === "error" && <div style={{ color: "red" }} dangerouslySetInnerHTML={{__html: message}}/>}
        {status === "success" && <div style={{ color: "green" }}>Subscribed !</div>}
      </div>
    )}
  />
)

const SignUpText = () => (
  <div>
   <p>
     Hey, I'd love to send you the extremely rare email letting you know of any updates to Kili or helpful tips. Just enter your email address in this box!
   </p>
   <br/>
   <p>
   கிலி அல்லது டிப்ஸ் பற்றிய மிகவும் பயனுள்ள புதுப்பிப்புகள் பற்றி உங்களுக்குத் தெரிவிக்கும் அரிய மின்னஞ்சலை உங்களுக்கு அனுப்ப விரும்புகிறேன். இந்தப் படிவத்தில் உங்கள் மின்னஞ்சல் முகவரியை உள்ளிடவும்!
   </p>
   <br/>
   </div>

)

const InstallLink = () => (
  <div>
    <a target="_blank" href="https://chrome.google.com/webstore/detail/kili/ngecplfghejkpepapelbaocakllgjilj/" target='_blank'>Install Kili from the Chrome Webstore</a>
  </div>
)

class KiliSignupPage extends React.Component {

  render() {
    return (
      <Layout>
        <InstallLink/>
        <br/>
        <SignUpText/>
        <CustomForm />
      </Layout>
      );
  }
}

export default function KiliSignUp() {
  return (
    <KiliSignupPage/>
  )
}